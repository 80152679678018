.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: visible;
  max-width: calc(100vw - 40px);
  background-color: #282829;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 130%;
  white-space: nowrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #282829 transparent transparent;
}

.custom-input-search::placeholder {
  color: white !important;
  opacity: 1; /* Ensure the color is not transparent */
}

.custom-input-container .ant-input::placeholder {
  color: white !important;
  opacity: 1;
}
.time-range-select {
  background: black;
}

.time-range-select .ant-select-selector {
  background: black !important;
  color: white !important;

  .ant-select-selector {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px !important;
  }
  font-weight: 600 !important;
  font-size: 14px !important ;
  padding: 20px !important;
  color: white !important;
}

.time-range-select .ant-select-arrow {
  color: white;
}

@media (max-width: 450px) {
  .time-range-select .ant-select-selector {
    font-weight: 500 !important;
    font-size: 12px !important ;
    padding: 10px !important;
    color: white !important;
  }
}

.customSlider {
  .ant-slider .ant-slider-track {
    background-color: #ff5612 !important;
  }
  .ant-slider .ant-slider-rail {
    background-color: #ffffff33 !important;
  }
}

.customSliderVolume {
  .ant-slider .ant-slider-track {
    background-color: white !important;
  }
  .ant-slider .ant-slider-rail {
    background-color: #ffffff33 !important;
  }
}

.pdf-container {
  overflow: auto !important;
  touch-action: pan-x pan-y !important;
}

// Antd Select
.antdSelectDropdown {
  max-height: 150px;
  overflow-y: auto;
  touch-action: none !important; /* Prevents background scroll while selecting */
}
/* Target Antd Select Dropdown Scrollbar */
.custom-select-dropdown .ant-select-dropdown-menu {
  max-height: 250px !important;
  overflow-y: auto !important;
}

.custom-select-dropdown-scroll-mobile {
  touch-action: manipulation !important;
  -webkit-overflow-scrolling: touch !important;
  overflow-y: auto !important;
}

/* For Firefox */

.rc-virtual-list-scrollbar-thumb {
  background: #a5a5a5 !important;
  width: 16px !important;
  border-radius: 2px !important;
  transform: translateX(-10px) !important;
  min-height: 80px !important;
}

// Disable scroll in parent
body.modal-open {
  overflow: hidden !important;
  height: 100vh !important;
}
